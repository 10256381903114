import { DefaultSeo } from 'next-seo';

import { setContext } from '@sentry/nextjs';
import RenderGA from 'app/components/Common/RenderGA';
import useCookie from 'app/hooks/use-cookie';
import { cookieCountries } from 'app/modules/Constants';
import type { AppProps } from 'next/app';
import { Assistant } from 'next/font/google';
import { useSearchParams } from 'next/navigation';
import Script from 'next/script';
import { FC, useEffect, useState } from 'react';
import { Provider, connect, useDispatch } from 'react-redux';
import { agreeCookieNotice, setLanguage } from '../actions/baseActions';
import SchemeHead from '../app/components/Common/SchemeHead';
import { useStore } from '../store';
import '../styles/app.scss';
import '../styles/responsive.scss';

const assistant = Assistant({
	subsets: ['latin-ext'],
	display: 'swap',
	weight: ['400', '500', '600', '700', '800'],
	variable: '--font-assistant',
});

export const initializeConsent = () => {
	if (typeof window !== 'undefined') {
		window.dataLayer = window.dataLayer || [];
		window.gtag =
			window.gtag ||
			function () {
				// eslint-disable-next-line prefer-rest-params
				(window.dataLayer = window.dataLayer || []).push(arguments);
			};

		window.gtag('consent', 'default', {
			functionality_storage: 'denied',
			security_storage: 'granted',
			ad_personalization: 'denied',
			ad_storage: 'denied',
			ad_user_data: 'denied',
			analytics_storage: 'denied',
			personalization_storage: 'denied',
			region: cookieCountries,
		});

		window.gtag('set', 'ads_data_redaction', true);
		window.gtag('set', 'url_passthrough', false);
	}
};

const App: FC<AppProps> = ({ Component, pageProps }) => {
	const [renderScript, setRenderScript] = useState(false);
	const [hydrated, setHydrated] = useState(false);
	const [isSiteLoaded, setIsSiteLoaded] = useState(false);
	const [clarityUserId] = useCookie('_clck');
	const [userIdAvailable, setUserIdAvailable] = useState(false);

	const dispatch = useDispatch();
	const params = useSearchParams();
	const lng = params?.get('lng') as string;

	if (lng && typeof document !== 'undefined') {
		document.documentElement.lang = lng;
	}

	useEffect(() => {
		let agreeCookieNoticeValue;
		try {
			agreeCookieNoticeValue = localStorage.getItem('cookieNotice');
			if (agreeCookieNoticeValue) {
				dispatch(agreeCookieNotice());
			}
		} catch (error) {
			console.error('Failed to read from localStorage, fallback to default value:', error);
		}
		initializeConsent();
		setHydrated(true);
	}, []);

	useEffect(() => {
		if (typeof window !== 'undefined') {
			const shouldRenderScript = !window.location.pathname.includes('/passthru');
			setRenderScript(shouldRenderScript);
		}
		if (!isSiteLoaded) {
			window.addEventListener('load', () => {
				setIsSiteLoaded(true);
			});
		}
	}, []);

	useEffect(() => {
		if (clarityUserId && !userIdAvailable) {
			setUserIdAvailable(true);
			setContext('Clarity User', {
				id: clarityUserId,
			});
		}
	}, [clarityUserId]);

	if (!hydrated) {
		// Returns null on first render, so the client and server match
		return null;
	}

	return (
		<>
			<SchemeHead />
			<RenderGA />
			{isSiteLoaded && renderScript && (
				<Script
					strategy="lazyOnload"
					id="Cookiebot"
					src="https://consent.cookiebot.com/uc.js"
					data-cbid="c2a9c7fe-f6d9-44f3-b48e-5ecea30b768d"
					type="text/javascript"
				/>
			)}
			{/* <Script strategy="lazyOnload" id="googleads"  data-ad-client="ca-pub-2732183997877262" async
                    src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"/> */}
			<DefaultSeo
				openGraph={{
					type: 'website',
					locale: 'en_IE',
					url: process.env.NEXT_PUBLIC_SITE_URL,
					site_name: process.env.NEXT_PUBLIC_SITE_NAME,
				}}
			/>
			<main className={assistant.variable}>
				<Component {...pageProps} />
			</main>
		</>
	);
};

const ConnectedMyApp = connect(null, { setLanguage, agreeCookieNotice })(App);

function AppWithProvider({ Component, pageProps, router }: AppProps) {
	const store = useStore(pageProps.initialReduxState);

	// const { growthbook, callback: growthbookCallback } = useGrowthbook();
	// store.dispatch(setGrowthbookCallback(growthbookCallback));

	return (
		// <GrowthBookProvider growthbook={growthbook}>
		<Provider store={store}>
			<ConnectedMyApp Component={Component} pageProps={pageProps} router={router} />
		</Provider>
		// </GrowthBookProvider>
	);
}

export default AppWithProvider;

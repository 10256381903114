'use client';

import BingUETScript from 'app/components/Common/BingUETScript';
import ClarityScript from 'app/components/Common/ClarityScript';
import FBPixelScript from 'app/components/Common/FBPixelScript';
import KlaviyoScript from 'app/components/Common/KlaviyoScript';
import useIdleLoading from 'app/hooks/useIdleLoading';
import dynamic from 'next/dynamic';
import React, { FC, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';

interface RenderGAProps {
	uid: string;
	imid: string;
}

const DynamicGoogleAnalytics = dynamic(() => import('@next/third-parties/google').then((mod) => mod.GoogleAnalytics), {
	ssr: false,
});

const RenderGA: FC<RenderGAProps> = ({ uid, imid }) => {
	const isIdle = useIdleLoading();
	const [isGALoaded, setIsGALoaded] = useState(false);
	const hasLoadedGA = useRef(false);

	useEffect(() => {
		if (isIdle && !hasLoadedGA.current) {
			const checkGtagAndFireEvent = () => {
				if (typeof window !== 'undefined' && typeof window.gtag !== 'undefined') {
					setIsGALoaded(true);
				} else {
					setTimeout(checkGtagAndFireEvent, 100);
				}
			};

			checkGtagAndFireEvent();
		}
	}, [isIdle]);

	useEffect(() => {
		if (isGALoaded && uid && !hasLoadedGA.current) {
			const sessionID = uid + imid;

			window.gtag('set', {
				user_id: sessionID,
			});

			const fireEvent = () => {
				hasLoadedGA.current = true;
			};

			if (window.requestIdleCallback) {
				window.requestIdleCallback(fireEvent);
			} else {
				setTimeout(fireEvent, 0);
			}
		}
	}, [isGALoaded, uid]);

	return (
		isIdle && (
			<>
				<DynamicGoogleAnalytics dataLayerName="dataLayer" gaId={process.env.NEXT_PUBLIC_GA_ID as string} />
				<ClarityScript clarityId={process.env.NEXT_PUBLIC_CLARITY_ID as string} />
				<FBPixelScript pixelId="363719008945777" />
				<BingUETScript tagId="148000929" />
				<KlaviyoScript companyId="RPT2kL" />
			</>
		)
	);
};

const mapStateToProps = (state: any) => ({
	uid: state.base.uid,
	imid: state.base.imid,
});

export default connect(mapStateToProps)(React.memo(RenderGA));

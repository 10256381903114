import { useEffect, useState } from 'react';

const useIdleLoading = () => {
	const [isIdle, setIsIdle] = useState(false);

	useEffect(() => {
		const handleLoad = () => {
			const idleCallback = () => setIsIdle(true);

			if ('requestIdleCallback' in window) {
				setTimeout(idleCallback, 1500);
			} else {
				const timeoutId = setTimeout(idleCallback, 2000);

				return () => clearTimeout(timeoutId);
			}
		};

		if (document.readyState === 'complete') {
			handleLoad();
		} else {
			window.addEventListener('load', handleLoad);

			return () => {
				window.removeEventListener('load', handleLoad);
			};
		}
	}, []);

	return isIdle;
};

export default useIdleLoading;

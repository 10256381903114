'use client';

import Script from 'next/script';
import { FC } from 'react';

interface KlaviyoScriptProps {
	companyId: string;
}

const KlaviyoScript: FC<KlaviyoScriptProps> = ({ companyId }) => {
	return (
		<Script
			id="klaviyo-script"
			strategy="afterInteractive"
			async
			src={`//static.klaviyo.com/onsite/js/klaviyo.js?company_id=${companyId}`}
		/>
	);
};

export default KlaviyoScript;

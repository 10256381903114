/* eslint-disable @next/next/no-img-element */
import Head from 'next/head';
import Script from 'next/script';
import React from 'react';

interface FBPixelScriptProps {
	pixelId: string;
}

const FBPixelScript: React.FC<FBPixelScriptProps> = ({ pixelId }) => {
	return (
		<>
			<Head>
				<meta name="facebook-domain-verification" content="hulvbwbh4casep7nigfu3ecpa2rwor" />
			</Head>
			<Script id="fb-pixel" strategy="afterInteractive">
				{`
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '${pixelId}');
          fbq('track', 'PageView');
        `}
			</Script>
			<noscript>
				<img
					height="1"
					width="1"
					style={{ display: 'none' }}
					src={`https://www.facebook.com/tr?id=${pixelId}&ev=PageView&noscript=1`}
					alt=""
				/>
			</noscript>
		</>
	);
};

export default FBPixelScript;
